import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import './Contact.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const Contact = ({ contact }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const contactData = {
            first: firstName,
            last: lastName,
            email: email,
            comment: comment,
        };

        try {
            const response = await fetch('/api/contact', { // Updated to use the Vercel API route
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contactData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Success:', result);
            alert('Message sent successfully!');

            // Clear the form fields
            setFirstName('');
            setLastName('');
            setEmail('');
            setComment('');

        } catch (error) {
            console.error('Error:', error);
            alert('Failed to send the message.');
        }
    };

    return (
        <section className='contact-wrp' style={contact.bgimg} id='contact'>
            <Container>
                <div className='contact-main'>
                    <div className='contact-title'>
                        <div className='main-title'>
                            <h2>{contact.title}</h2>
                        </div>
                        <div className='common-detail'>
                            <p>{contact.detail}</p>
                        </div>
                        <div className='contact-detail'>
                            <ul>
                                <li><span><FontAwesomeIcon icon={faPhone} /></span><Link
                                    to={`tel:${contact.number}`}>{contact.number}</Link></li>
                                <li><span><FontAwesomeIcon icon={faEnvelope} /></span><Link
                                    to={`mailto:${contact.email}`}>{contact.email}</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='contact-form'>
                        <Form className='common-form' onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Group className="form-group" controlId="formFirstName">
                                        <Form.Label>{contact.formFirstName}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Group className="form-group" controlId="formLastName">
                                        <Form.Label>{contact.formLastName}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={12} lg={12} xl={12} xxl={12}>
                                    <Form.Group className="form-group" controlId="formEmail">
                                        <Form.Label>{contact.formEmail}</Form.Label>
                                        <Form.Control
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12} lg={12} xl={12} xxl={12}>
                                    <Form.Group className="form-group" controlId="formMessage">
                                        <Form.Label>{contact.formMessage}</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12} lg={12} xl={12} xxl={12}>
                                    <Form.Group className="form-group text-center">
                                        <Button variant="primary" className="btn btn-green" type="submit">
                                            {contact.btn}
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default Contact;
