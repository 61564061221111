import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "./StepOne.css";

const StepOne = ({stepOne}) => {
    return (
        <>
            <section className='step-one-wrp' style={stepOne.bgimg1} id='how-it-works'>
                <Container>
                    <div className='step-one-main'>
                        <Row className='align-items-center'>
                            <Col xxl={6} xl={6} lg={6}>
                                <div className='step-one-img'>
                                    <img src={stepOne.stepOneImg} alt={stepOne.alt} className='img-fluid' />
                                </div>
                            </Col>
                            <Col xxl={6} xl={6} lg={6}>
                                <div className='step-one-content'>
                                    <div className='step-one-block'>
                                        <div className='main-title'>
                                            <h1>{stepOne.maintitle}</h1>
                                        </div>
                                        <div className='common-detail'>
                                            <p>{stepOne.maindetail}</p>
                                        </div>
                                    </div>
                                    <div className='step-one-block'>
                                        <div className='main-title'>
                                            <h1>{stepOne.innertitle}</h1>
                                        </div>
                                        <div className='common-detail'>
                                            <p>{stepOne.innerdetail}</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default StepOne;