import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "./StepTwo.css";

const StepTwo = ({stepTwo}) => {
    return (
        <>
            <section className='step-two-wrp' style={stepTwo.bgimg}>
                <Container>
                    <div className='step-two-main'>
                        <Row className='align-items-center'>
                            <Col md={6} lg={6} xl={6} xxl={6}>
                                <div className='step-two-content'>
                                    <div className='main-title'>
                                        <h1>{stepTwo.maintitle}</h1>
                                    </div>
                                    <div className='common-detail'>
                                        <p>{stepTwo.maindetail}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} lg={6} xl={6} xxl={6}>
                                <div className='step-two-img'>
                                    <img src={stepTwo.stepOneImg} alt={stepTwo.alt} className='img-fluid' />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default StepTwo;