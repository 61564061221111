import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = ({footer}) => {
    const currentYear = new Date().getFullYear();

    return (
        <>
            <footer className='footer'>
                <div className='footer-top'>
                    <Container>
                        <div className='footer-top-inner'>
                            <div className='footer-logo'>
                                <Link to="/"><img src={footer.footerLogo} alt={footer.alt} className='img-fluid' /></Link>
                            </div>
                            <div className='footer-links'>
                                <ul>
                                    <li>{footer.phoneText}<Link to={`tel:${footer.phoneNumber}`}>{footer.phoneNumber}</Link></li>
                                    <li>{footer.mobileText}<Link to={`tel:${footer.mobileNumber}`}>{footer.mobileNumber}</Link></li>
                                    <li><Link to={`mailto:${footer.email}`}>{footer.email}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className='footer-bottom'>
                    <p>© {currentYear} Instant Elect. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    )
}

export default Footer;