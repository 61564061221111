import './App.css';
import './assets/fonts/fonts.css';
import Home from './Home';

function App() {
  return (
    <>
      <Home/>
    </>
  );
}

export default App;
