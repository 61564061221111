import React from 'react';
import Header from './components/shared/Header/Header';
import Banner from './components/Sections/Banner/Banner';
import { banner, benefits, contact, footer, stepOne, stepThree, stepTwo } from './components/Data/Data';
import StepOne from './components/Sections/StepOne/StepOne';
import StepTwo from './components/Sections/StepTwo/StepTwo';
import StepThree from './components/Sections/StepThree/StepThree';
import Benefits from './components/Sections/Benefits/Benefits';
import Footer from './components/shared/Footer/Footer';
import Contact from './components/Sections/Contact/Contact';

const Home = () => {
    return (
        <>
            <Header/>
            <Banner banner={banner}/>
            <StepOne stepOne={stepOne} />
            <StepTwo stepTwo={stepTwo} />
            <StepThree stepThree={stepThree}/>
            <Benefits benefits={benefits}/>
            <Contact contact={contact}/>
            <Footer footer={footer}/>
        </>
    )
}

export default Home;