import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../../../assets/images/logo.svg';
import { Link } from 'react-scroll';
import './Header.css';

const Header = () => {
    const headerHeight = 115; 
    const [expanded, setExpanded] = useState(false);

    const handleNavClick = () => {
        // Close the Navbar when a Nav.Link is clicked
        setExpanded(false);
    };
    
    const handleToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <header className='header'>
                <Navbar expand="md" expanded={expanded}>
                    <Container>
                        <Navbar.Brand href="#">
                            <img src={logo} alt='logo' className='img-fluid' />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Link to="home" spy={true} smooth={true} duration={500} offset={-headerHeight} className="nav-link" onClick={handleNavClick}>Home</Link>
                            <Link to="how-it-works" spy={true} smooth={true} duration={500} offset={-headerHeight} className="nav-link" onClick={handleNavClick}>How it works</Link>
                            <Link to="benefits" spy={true} smooth={true} duration={500} offset={-headerHeight} className="nav-link" onClick={handleNavClick}>Benefits</Link>
                            <Link to="contact" spy={true} smooth={true} duration={500} offset={-headerHeight} className="nav-link" onClick={handleNavClick} >Contact</Link>
                        </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    )
}

export default Header;