import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Banner.css';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCaretDown } from '@fortawesome/free-regular-svg-icons';

const Banner = ({banner}) => {
    const headerHeight = 115; 

    return (
        <>
            <section className='banner-wrp' style={banner.bgimg} id="home">
                <Container>
                    <div className='banner-main'>
                        <Row className='align-items-center justify-content-between'>
                            <Col md={6} lg={6} xl={6} xxl={6}>
                                <div className='banner-content'>
                                    <div className='main-title'>
                                        <h1>{banner.bannertitle}</h1>
                                    </div>    
                                    <div className='common-detail'>
                                        <p>{banner.bannerdetail}</p>
                                    </div>
                                    <Link to="how-it-works" className='btn btn-blue' offset={-headerHeight}>{banner.btn} <span><FontAwesomeIcon icon={faSquareCaretDown} /></span></Link>
                                </div>
                            </Col>
                            <Col md={6} lg={5} xl={5} xxl={5}>
                                <div className='banner-img'>
                                    <img src={banner.bannerimg} alt={banner.alt} className='img-fluid d-none d-md-block' />
                                    <img src={banner.bannermobile} alt={banner.alt} className='img-fluid d-block d-md-none' />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Banner;