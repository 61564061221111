import homeHeroBg from '../../assets/images/home-hero-bg.svg';
import stepOneBg from '../../assets/images/step-one-bg.svg';
import stepTwoBg from '../../assets/images/step-two-bg.svg';
import stepThreeBg from '../../assets/images/step-three-bg.svg';
import benefitsBg from '../../assets/images/benefits-bg.svg';
import contactBg from '../../assets/images/contact-bg.svg';


export const banner = {
    bgimg: {
        backgroundImage: `url(${homeHeroBg})`,
        backgroundSize: '130%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 40%',
    },
    bannertitle: (
        <>Say <span>goodbye</span> to <span>paper</span> ballots</>
    ),
    highlight: "goodbye",
    bannerdetail: "After ten years serving on tabulating committees, we decided there must be a better way. With our background in computer science and web technologies, it made sense to develop an online solution. After eight years of providing election software support, we are confident you will love the simplicity, speed, and security of our electronic voting system.",
    btn: "See how it works",
    bannerimg: require('../../assets/images/paper-shredder.svg').default,
    bannermobile: require('../../assets/images/paper-shredder-mobile.svg').default,
    alt: "banner img"
}

export const stepOne = {
    bgimg1: {
        backgroundImage: `url(${stepOneBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center center',
    },
    maintitle: "How it works",
    maindetail: "Instead of paper ballots, all voting occurs on your voter’s smartphone, tablet or laptop computer. With our completely anonymous and encrypted technology, we keep each vote secret and secure. Here's the simple process:",
    innertitle: "Step One",
    innerdetail: "After we enter your organization’s criteria and eligible candidates, we set up the nominating ballots, electoral ballots and motions. Then we distribute credentials to all of the qualified voters. The credentials consists of a business card with login credentials and a QR code. Voters can scan the QR code or enter the login credentials manually to begin. Usually, it will take about 10 seconds for voters to get setup and ready.",
    stepOneImg: require('../../assets/images/login-mockup.gif'),
    alt: "Step One Image"
}

export const stepTwo = {
    bgimg: {
        backgroundImage: `url(${stepTwoBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll',
        backgroundPosition: '-70% 0%',
    },
    maintitle: "Step Two",
    maindetail: "After voters have signed in, the chair will open a poll. Voters will see on options on their screen based on the election type. Voters can change their vote until the poll closes. If the voter changes their vote, only the last vote in the poll will count.",
    stepOneImg: require('../../assets/images/voting-mockup.webp'),
    alt: "Step Two Image"
}

export const stepThree = {
    bgimg: {
        backgroundImage: `url(${stepThreeBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // backgroundAttachment: 'fixed',
        // backgroundPosition: 'center 19.4531px',
    },
    maintitle: "Step Three",
    maindetail: "The chair views the results in real time with several metrics, including: current election status, total voters, duration of the poll, time since last vote, total votes, candidate names, votes, and corresponding percentages for each. After the chair closes the poll, they can announce the results. Upon successful completion of the poll, a PDF of the election report is immediately generated and available.",
    btn: "See more benefits",
    stepOneImg: require('../../assets/images/Mockup-laptop.webp'),
    alt: "Step Three Image",
}

export const benefits = {
    bgimg: {
        backgroundImage: `url(${benefitsBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll',
        backgroundPosition: '50% 0%',
    },
    title: "Benefits",
    blocks: [
        {
            title: "No More Wasted Votes",
            items: [
                "Unqualified candidates do not appear.",
                "Candidates who have resigned or declined do not appear.",
            ]
        },
        {
            title: "Remove Rule-Based Decision Errors",
            items: [
                "Term-limits procedure is automatically calculated.",
                "Simple and 2/3 majority polls are automatically calculated.",
            ]
        },
        {
            title: "Eliminate Voters Doubting Their Vote Counted",
            items: [
                "Voters receive instant vote confirmation.",
                "Confirmation contains candidate name, city, and state.",
            ]
        },
        {
            title: "Instant Voting Results",
            items: [
                "Chair and Tabulating Committee view real-time results.",
                "Upon closing polls, results instantly display",
            ]
        },
        {
            title: "Automated Elections Reporting",
            items: [
                "Headquarters Representative and District Superintendent receive elections report at the conclusion of the election.",
            ]
        }
    ]
}

export const contact = {
    bgimg: {
        backgroundImage: `url(${contactBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center center',
    },
    title: (
        <><span>Contact</span> Us</>
    ),
    detail: "We’d love to speak with you. Send us a message and we’ll respond as quickly as possible.",
    number: "(601) 633-9090",
    email: "admin@instantelect.com",
    formFirstName: (
        <>First Name<small>(Required)</small></>
    ),
    formLastName: (
        <>Last Name<small>(Required)</small></>
    ),
    formEmail: (
        <>Email<small>(Required)</small></>
    ),
    formNumber: (
        <>Phone<small>(Required)</small></>
    ),
    formMessage: (
        <>Your Message<small>(Required)</small></>
    ),
    btn: "Submit request",
}

export const footer = {
    footerLogo: require('../../assets/images/footer-logo.svg').default,
    alt: "Logo",
    phoneText: "Ph.",
    mobileText: "Mbl.",
    phoneNumber: "(601) 633-9090",
    mobileNumber: "(503) 805-9960",
    email: "admin@instantelect.com"
}