import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "./Benefits.css";

const Benefits = ({benefits}) => {
    return (
        <>
            <section className='benefits-wrp' style={benefits.bgimg} id='benefits'>
                <Container>
                    <div className='benefits-main'>
                        <Row className='justify-content-center'>
                            <Col lg={10} xl={9} xxl={8}>
                                <div className='benefits-content'>
                                    <div className='main-title'>
                                        <h2>{benefits.title}</h2>
                                    </div>
                                    {benefits.blocks.map((block, index) => (
                                        <div key={index} className='benefits-details'>
                                            <div className='block-title'>
                                                <h3>{block.title}</h3>
                                            </div>
                                            <div className='common-detail'>
                                                <h4>{block.subtitle}</h4>
                                                <ul>
                                                    {block.items.map((item, itemIndex) => (
                                                        <li key={itemIndex}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Benefits;