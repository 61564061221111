import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "./StepThree.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCaretDown } from '@fortawesome/free-regular-svg-icons';
// import { Parallax } from 'react-parallax';
import { Link } from 'react-scroll';

const StepThree = ({stepThree}) => {
    const headerHeight = 115; 
    
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => {
        setOffsetY(window.pageYOffset);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <section className='step-three-wrp' style={{ backgroundImage: stepThree.bgimg.backgroundImage, backgroundPosition: `center ${-15.0469 + offsetY * 0.35}px`, }}>
                <Container>
                    <div className='step-three-main'>
                        <Row className='align-items-center'>
                            <Col lg={6} xl={6} xxl={6}>
                                <div className='step-three-img'>
                                    <img src={stepThree.stepOneImg} alt={stepThree.alt} className='img-fluid' />
                                </div>
                            </Col>
                            <Col lg={6} xl={6} xxl={6}>
                                <div className='step-three-content'>
                                    <div className='main-title'>
                                        <h1>{stepThree.maintitle}</h1>
                                    </div>
                                    <div className='common-detail'>
                                        <p>{stepThree.maindetail}</p>
                                    </div>
                                    <Link to="benefits" className='btn btn-blue' offset={-headerHeight}>{stepThree.btn} <span><FontAwesomeIcon icon={faSquareCaretDown} /></span></Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default StepThree;